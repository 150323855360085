import React, { HTMLAttributes } from 'react';
interface IconProps extends HTMLAttributes<SVGElement> {
	width?: number;
	height?: number;
}
export const Logo = ({  height = 20, width = 180, ...restProps }: IconProps) => {
	return (
    <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" height={height} width={width} {...restProps} />
	);
};
